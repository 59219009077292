function escape_html(atxt) {
  return atxt
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&rsquo;')
    .replace(/`/g, '&lsquo;');
}

function shweb_call(aurl, apostbody, aeventcall, ahourglasselement) {
  var http = new XMLHttpRequest();
  
  if (aeventcall != null) {
    http.onreadystatechange = function() {
      if ((http.readyState == 4) && (http.status = 200)) aeventcall(http.responseText);
    }
  }
              
  if (ahourglasselement != null) ahourglasselement.innerHTML = '<img src="/assets/loading.gif" style="height: 25px">';
  http.open('POST', aurl, true);
  http.send(apostbody);  
}

function kickstart_app() {
  if (document.getElementById('main_element') == null) {
    console.log('Cannot kickstart app. main_element not found');
    return;
  }
  
  var request = {};
  request.js_cache_version = 1;
  shweb_call('/shweb/main/test_auth', JSON.stringify(request),
    function (areplybody) {
      try {
        var reply = JSON.parse(areplybody);
      } catch {
        console.log('Cannot kickstart app. Auth replied with [' + areplybody + ']');
        return;
      }
      if (reply.authenticated) { render_app(); } else { location.assign('/login.html'); }
    },
    main_element
  );
}

function main_user_auth() {
  var request = {};
  request.user_name = input_usrname.value;
  request.password = input_pwd.value;
  shweb_call('/shweb/main/auth', JSON.stringify(request),
    function (areplybody) {
      if (areplybody == 'OK') {
        div_auth_msg.innerHTML = '<span style="color: green">Success... Redirecting</span>';
        location.assign('/');
      } else {
        div_auth_msg.innerHTML = '<span style="color: red">' + escape_html(areplybody) + '</span>';
        btn_login.innerHTML = 'Log in';
      }
    },
    btn_login
  );
}

function logout() {
  shweb_call('/shweb/main/logout', null,
    function (areplybody) {
      location.assign('/');
    },
    main_element
  );
}

function push_session_object(aobj) {
  input_session_cache.value = JSON.stringify(aobj);
}

function render_app(aappname) {
  if (aappname == null) aappname = 'main';
  
  try {
    var session_obj = JSON.parse(input_session_cache.value);
  } catch(err) {
    session_obj = {};
    push_session_object(session_obj);
  }
  
  session_obj.app_position = aappname;
  push_session_object(session_obj);
  
  shweb_call('/shweb/app/render', JSON.stringify(session_obj),
    function (areplybody) {
      main_element.innerHTML = areplybody;
    },
    main_element
  );
}

function view_account(aaccno) {
  var request = {};
  
  request.account_id = aaccno;
  
  shweb_call('/shweb/account/view', JSON.stringify(request),
    function (areplybody) {
      main_element.innerHTML = areplybody;
    },
    main_element
  );  
}

function send_funds_launch(aaccno) {
  var request = {};
  
  request.account_id = aaccno;
  
  shweb_call('/shweb/account/send/launch', JSON.stringify(request),
    function (areplybody) {
      main_element.innerHTML = areplybody;
    },
    main_element
  );  
}

function send_funds_submit(aaccno) {
  var request = {};
  
  request.account_id = aaccno;
  request.destination_account = input_dst_account.value;
  request.transmission_amount = input_transmit_amount.value;
  
  shweb_call('/shweb/account/send/submit', JSON.stringify(request),
    function (areplybody) {
      div_feedback.innerHTML = areplybody;
    },
    div_feedback
  );
}
